import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
})
export class LogoComponent implements OnInit {

  constructor() {
  }

  @Input()
  public size: 'small' | 'default' | 'big' = 'default';

  ngOnInit(): void {
  }

}
